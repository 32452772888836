<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import arce from '@/services/arce'
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
		ArceWizard: () => import('@/components/simulator/arce/ArceWizard'),
	},

	data() {
		return {
			query: '',
			listArce: []
		}
	},

	computed: {
		filteredClients() {
			if(!this.query) {
				return this.allClients
			}
			return this.searchClients(this.query)
		},
		...mapGetters({
			'arceRatingList': 'arceRatings/list'
		})
	},

	methods: {
		...mapMutations({
			showSearchClient: 'arceStatus/showSearchClient'
		}),
	},

	mounted() {
		this.fetchAllArce()
		// this.hiddenSteps()
		// this.showSteps('myTourClients')
	}

}
</script>
<template>
	<div class="d-flex flex-wrap px-1 py-1 s-single">
		<div class="col-12 p-0">
			<div class="d-flex flex-wrap py-1">
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__header bg-light p-3">
							<div class="d-flex align-items-center">
								<div class="s-element__icon">
									<img src="@/assets/simulator/account_120.png" alt="">
								</div>
								<div>
									<p class="m-0">ARCE</p>								
									<h3 class="title underline">
										<a href="#" class="text-dark">
											Recently Viewed 
											<span class="mt-1">
												<svg-icon name="down" />
											</span>
										</a>
									</h3>
									<button class="btn btn-light btn-sm px-2 mx-1 btn-sub text-info">
										<span class="mt-1">
												<svg-icon name="pinned" />
											</span>
									</button>										
									
								</div>
                                
								<div class="ml-auto d-flex">							
									<div class="ml-3 s-btn-group">
										<button @click="showSearchClient" class="s-btn s-btn--outline" data-v-step="2">
											New
										</button>
									</div>
								</div>
							</div>
							<div class="d-flex align-items-center">
								<div>
                                    <p class="mb-0 mt-3 text-muted small" v-if="arceRatingList.length">{{ arceRatingList.length }} items - Updated a few seconds ago</p>
									<!-- <p>2 elementos - Se actualizó hace unos segundos</p> -->
								</div>
								<div class="ml-auto d-flex align-items-center s-table__controls">
									<div class="s-input">
										<input data-v-step="3" type="text" placeholder="Buscar en esta lista" v-model="query">
										<svg-icon class="mx-2" name="search" />
										<span @click="query = ''" v-if="query">
											<svg-icon class="mx-2 s-input__delete" name="close" />
										</span>
									</div>
									<button class="px-2 mx-1">
										<svg-icon name="settings" />
									</button>
									<button class="px-2 mx-1">
										<svg-icon name="table" />
									</button>
									<button class="px-2 mx-1">
										<svg-icon name="refresh" />
									</button>
									<button class="px-2 mx-1">
										<svg-icon name="edit" />
									</button>
									
									<button class="px-2">
										<svg-icon name="chart" />
									</button>
									<button class="px-2">
										<svg-icon name="filterList" />
									</button>
								</div>
							</div>
						</div>
						<div class="s-panel__body p-0">
							<table class="s-table w-100">
								<thead>
									<tr>
										<th></th>
										<th>
											Analysis name
										</th>
										
										<th>
											
										</th>
									</tr>
								</thead>
								<tbody v-if="arceRatingList.length">
									<tr v-for="(item, index) in arceRatingList" :key="`arce-${index}`">
										<td >
											{{ index + 1 }}
										</td>
										<td class="w-100">
											<router-link :to="{name:'ArceRating', params: {id: item.id}}">
												Rating - {{ item.nombre }} - {{ item.folio }}
											</router-link>
										</td>
										<td style="white-space:nowrap">
											<button class="s-btn s-btn--outline p-1" style="min-height: auto; height: 24px;">
												<SvgIcon name="down"/>
											</button>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="4" class="text-center">
											No encontramos registros con esta busqueda {{ query }}
										</td>
									</tr>
                                    
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>